import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Thanks = () => (
  <Layout>
    <SEO title="Subscription confirmed" />

    <div className="container contact thanks-container">
      <div className="contact__content">
        <h1>Thanks for subscribing!</h1>
      </div>
    </div>
  </Layout>
)

export default Thanks
